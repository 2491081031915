import React, { Suspense, lazy } from 'react';
import { Routes as RoutesWrapper, Route, Navigate } from 'react-router-dom';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword';
import NotFound from '../pages/404';
import NotPermitted from '../pages/NotPermitted';
import EditStatics from '../pages/statics/EditStatics';
import AccentSidebarLayout from '../components/layouts/AccentSidebarLayout';
import GeneralLayout from '../components/layouts/GeneralLayout';
import DashboardLayout from '../components/layouts/DashboardLayout';
import SuspenseLoader from '../components/layouts/SuspenseLoader';
import WrapperComponent from '../pages/dashboard/components/WrapperComponent';
import { ROUTE_CONSTANTS } from '../constants/AppRoutes';
import ProtectedRoutes from './ProtectedRoutes';

const Analytics = Loader(lazy(() => import('../pages/dashboard/Analytics')));
const MyProfile = Loader(
	lazy(() => import('../pages/dashboard/users/MyProfile'))
);
const AdminList = Loader(lazy(() => import('../pages/dashboard/users/Admins')));
const CustomerList = Loader(
	lazy(() => import('../pages/dashboard/users/Customers'))
);
const UserDetails = Loader(
	lazy(() => import('../pages/dashboard/users/Details'))
);
const Cars = Loader(lazy(() => import('../pages/dashboard/vehicles/Cars')));
const AddUpdateCar = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Cars/AddUpdateCar'))
);
const CarDetails = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Cars/CarDetails'))
);
const Brands = Loader(lazy(() => import('../pages/dashboard/vehicles/Brands')));
const AddUpdateBrand = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Brands/AddUpdateBrand'))
);
const Classes = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Classes'))
);
const AddUpdateClass = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Classes/AddUpdateClass'))
);
const Vendors = Loader(
	lazy(() => import('../pages/dashboard/logistics/Vendors'))
);
const InventoryDetails = Loader(
	lazy(() =>
		import(
			'../pages/dashboard/logistics/Vendors/VendorDetails/inventory/InventoryDetails'
		)
	)
);
const OrderDetails = Loader(
	lazy(() =>
		import(
			'../pages/dashboard/logistics/Vendors/VendorDetails/Orders/OrderDetails'
		)
	)
);
const AddUpdateVendor = Loader(
	lazy(() => import('../pages/dashboard/logistics/Vendors/AddUpdateVendor'))
);
const VendorDetails = Loader(
	lazy(() => import('../pages/dashboard/logistics/Vendors/VendorDetails'))
);
const AddUpdateInventory = Loader(
	lazy(() =>
		import(
			'../pages/dashboard/logistics/Vendors/VendorDetails/inventory/AddUpdateInventory'
		)
	)
);
const Countries = Loader(
	lazy(() => import('../pages/dashboard/logistics/Countries'))
);
const AddUpdateCountry = Loader(
	lazy(() => import('../pages/dashboard/logistics/Countries/AddUpdateCountry'))
);
const AllOrders = Loader(
	lazy(() => import('../pages/dashboard/logistics/AllOrders'))
);
const Administration = Loader(
	lazy(() => import('../pages/dashboard/Administration'))
);
const StaticPage = Loader(lazy(() => import('../pages/statics/StaticPage')));
const Colors = Loader(
	lazy(() => import('../pages/dashboard/logistics/Colors'))
);
const AddUpdateColor = Loader(
	lazy(() => import('../pages/dashboard/logistics/Colors/AddUpdateColor'))
);
const Airports = Loader(
	lazy(() => import('../pages/dashboard/logistics/Airports'))
);
const AddUpdateAirport = Loader(
	lazy(() => import('../pages/dashboard/logistics/Airports/AddUpdateAirport'))
);
const AddUpdateOfflineOrder = Loader(
	lazy(() => import('../pages/dashboard/logistics/OfflineOrder'))
);
const Currencies = Loader(
	lazy(() => import('../pages/dashboard/logistics/Currencies'))
);
const AddUpdateCurrency = Loader(
	lazy(() =>
		import('../pages/dashboard/logistics/Currencies/AddUpdateCurrency')
	)
);
const UnavailableVehicles = Loader(
	lazy(() => import('../pages/dashboard/vehicles/Unavailable'))
);
const Faqs = Loader(lazy(() => import('../pages/faqs')));
const Messages = Loader(lazy(() => import('../pages/messages')));
const MessageDetail = Loader(
	lazy(() => import('../pages/messages/MessageDetail'))
);

function Loader(Component) {
	return (props) => (
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);
}

const AppRouter = () => {
	return (
		<RoutesWrapper>
			<Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<GeneralLayout />}>
				<Route path='' element={<Login />} />
			</Route>
			<Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<NotFound />} />
			<Route
				path={ROUTE_CONSTANTS.PUBLIC_ROUTES.NOT_PERMITTED.ABSOLUTE}
				element={<NotPermitted />}
			/>
			<Route
				path={ROUTE_CONSTANTS.PUBLIC_ROUTES.STATIC_PAGES.ROOT.ABSOLUTE}
				element={<StaticPage />}
			/>
			<Route
				path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE}
				element={<GeneralLayout />}
			>
				<Route
					path=''
					element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />}
				/>
				<Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
				<Route
					path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE}
					element={<ResetPassword />}
				/>
				<Route
					path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE}
					element={<ForgotPassword />}
				/>
			</Route>
			<Route path={''} element={<DashboardLayout />}>
				<Route
					path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE}
					element={<AccentSidebarLayout />}
				>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.ANALYTICS.RELATIVE}
						element={<Analytics />}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.USERS.PROFILE.ROOT.RELATIVE}
						element={<MyProfile />}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.USERS.ROOT.RELATIVE}
						element={<WrapperComponent category='USERS' />}
					>
						<Route
							index
							element={
								<ProtectedRoutes role='GET_ADMIN_LIST'>
									<AdminList />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_ADMIN_LIST'>
									<AdminList />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.DETAILS.RELATIVE}
							element={
								<ProtectedRoutes role='GET_ADMIN_DETAIL'>
									<UserDetails />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.USERS.CUSTOMERS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_CUSTOMER_LIST'>
									<CustomerList />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.USERS.CUSTOMERS.DETAILS.RELATIVE}
							element={
								<ProtectedRoutes role='GET_CUSTOMER_DETAIL'>
									<UserDetails />
								</ProtectedRoutes>
							}
						/>
					</Route>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.ROOT.RELATIVE}
						element={<WrapperComponent category='LOGISTICS' />}
					>
						<Route
							index
							element={
								<ProtectedRoutes role='GET_COUNTRY_LIST'>
									<Countries />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COUNTRIES.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_COUNTRY_LIST'>
									<Countries />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COUNTRIES.ADD_NEW_COUNTRY
									.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_COUNTRY'>
									<AddUpdateCountry addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COUNTRIES.UPDATE_COUNTRY
									.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_COUNTRY'>
									<AddUpdateCountry addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_VENDOR_LIST'>
									<Vendors />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_VENDOR_DETAIL'>
									<VendorDetails />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS.MY_VENDOR
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_MY_VENDOR_VENDOR_DETAIL'>
									<VendorDetails myVendor={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS
									.ADD_NEW_INVENTORY.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_INVENTORY'>
									<AddUpdateInventory addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS
									.UPDATE_INVENTORY.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_INVENTORY'>
									<AddUpdateInventory addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS
									.INVENTORY_DETAILS.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_INVENTORY_DETAIL'>
									<InventoryDetails />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.DETAILS
									.ORDER_DETAILS.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_ORDER_DETAIL'>
									<OrderDetails />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.ADD_NEW_VENDOR.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_VENDOR'>
									<AddUpdateVendor addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.VENDORS.UPDATE_VENDOR.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_VENDOR'>
									<AddUpdateVendor addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.ALL_ORDERS.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_ORDER_DETAIL'>
									<AllOrders />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COLORS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_COLOR_LIST'>
									<Colors />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COLORS.ADD_NEW_COLOR.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_COLOR'>
									<AddUpdateColor addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.COLORS.UPDATE_COLOR.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_COLOR'>
									<AddUpdateColor addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.AIRPORTS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_COUNTRY_AIR_LIST'>
									<Airports />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.AIRPORTS.ADD_NEW_AIRPORT
									.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_COUNTRY_AIR'>
									<AddUpdateAirport addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.AIRPORTS.UPDATE_AIRPORT.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_COUNTRY_AIR'>
									<AddUpdateAirport addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.OFFLINE_ORDERS
									.ADD_NEW_OFFLINE_ORDERS.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='OFFLINE_ORDER'>
									<AddUpdateOfflineOrder addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.CURRENCIES.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_CURRENCY'>
									<Currencies />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.LOGISTICS.CURRENCIES.UPDATE_CURRENCY
									.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_CURRENCY'>
									<AddUpdateCurrency addNew={false} />
								</ProtectedRoutes>
							}
						/>
					</Route>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.ROOT.RELATIVE}
						element={<WrapperComponent category='VEHICLES' />}
					>
						<Route index element={<Cars />} />
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CARS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_VEHICLE_LIST'>
									<Cars />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CARS.ADD_NEW_CAR.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_VEHICLE'>
									<AddUpdateCar addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CARS.UPDATE_CAR.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_VEHICLE'>
									<AddUpdateCar addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CARS.DETAILS.RELATIVE}
							element={
								<ProtectedRoutes role='GET_VEHICLE_DETAIL'>
									<CarDetails />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.BRANDS.ROOT.RELATIVE}
							element={
								<ProtectedRoutes role='GET_BRANDLIST'>
									<Brands />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.BRANDS.ADD_NEW_BRAND.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_BRAND'>
									<AddUpdateBrand addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.BRANDS.UPDATE_BRAND.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_BRAND'>
									<AddUpdateBrand addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CAR_CLASSES.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='GET_CLASS_LIST'>
									<Classes />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CAR_CLASSES.ADD_NEW_CLASS
									.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='CREATE_CLASS'>
									<AddUpdateClass addNew={true} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CAR_CLASSES.UPDATE_CLASS.ROOT
									.RELATIVE
							}
							element={
								<ProtectedRoutes role='UPDATE_CLASS'>
									<AddUpdateClass addNew={false} />
								</ProtectedRoutes>
							}
						/>
						<Route
							path={
								ROUTE_CONSTANTS.DASHBOARD.VEHICLES.UNAVAILABLE.ROOT.RELATIVE
							}
							element={
								<ProtectedRoutes role='UNAVAILABLE_INVENTORY_LIST'>
									<UnavailableVehicles addNew={false} />
								</ProtectedRoutes>
							}
						/>
					</Route>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROOT.RELATIVE}
						element={
							<ProtectedRoutes role='ROLE_CONTROLLER'>
								<Administration />
							</ProtectedRoutes>
						}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.EDIT_STATIC_PAGES.ROOT.ABSOLUTE}
						element={
							<ProtectedRoutes role='GET_STATIC_PAGE_LIST'>
								<EditStatics />
							</ProtectedRoutes>
						}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.FAQ.ROOT.ABSOLUTE}
						element={
							<ProtectedRoutes role='FAQ'>
								<Faqs />
							</ProtectedRoutes>
						}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.MESSAGES.ROOT.RELATIVE}
						element={
							<ProtectedRoutes role='CONTACT_LIST'>
								<Messages />
							</ProtectedRoutes>
						}
					/>
					<Route
						path={ROUTE_CONSTANTS.DASHBOARD.MESSAGES.DETAILS.ROOT.RELATIVE}
						element={
							<ProtectedRoutes role='CONTACT_LIST'>
								<MessageDetail />
							</ProtectedRoutes>
						}
					/>
				</Route>
			</Route>
		</RoutesWrapper>
	);
};
export default AppRouter;
